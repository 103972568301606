<template>
  <div class="food-container">
    <el-table :data="foodRankTable" border style="width: 100%">
      <el-table-column prop="storeName" label="店铺名" width="180" />
      <el-table-column prop="storeJudge" label="店铺评价" width="180" />
      <el-table-column prop="foodType" label="食物种类" width="180" />
      <el-table-column prop="foodName" label="食物名" width="180" />
      <el-table-column prop="foodPrice" label="食物价格" width="180" />
      <el-table-column prop="foodScore" label="食物评分" width="180" />
      <el-table-column prop="orderCount" label="下单次数" width="180" />
    </el-table>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const foodRankTable = ref([]);

onMounted(async () => {
  try {
    const response = await axios.post('https://www.escapethewind.cn/async/api/food/getAllFoodInfo');
    foodRankTable.value = response.data;
  } catch (error) {
    console.error('Failed to fetch data:', error);
  }
});
</script>