<template>
  <div class="home-container">
    <h2>Welcome to async</h2>
    <button @click="navigateToFoodSection">Food Section</button>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';

const router = useRouter();

const navigateToFoodSection = () => {
  router.push('/food');
};

</script>

<style scoped>
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
}

.home-container h2 {
  color: #ffffff;
  font-size: 2em;
}

.home-container button {
  margin-top: 30px;
  padding: 10px 20px;
  font-size: 1em;
  background-color: #1e90ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.home-container button:hover {
  background-color: #0069d9;
}
</style>