<template>
  <div class="login-form">
    <h2>Login</h2>
    <form @submit.prevent="handleLogin" id="loginForm">
      <label for="username">Username:</label>
      <input type="text" id="username" v-model="username" required>

      <label for="password">Password:</label>
      <input type="password" id="password" v-model="password" required>

      <button type="submit">Login</button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'LoginView',
  data() {
    return {
      username: '',
      password: ''
    };
  },
  methods: {
    async handleLogin() {
      try {
        const response = await fetch('https://www.escapethewind.cn/async/auth/signIn', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userName: this.username, password: this.password })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        if (data.responseCode === 200) {
          this.$router.push('/home'); // 确保这里可以访问 $router
        } else {
          alert("Login failed: " + data.responseMessage);
        }
      } catch (error) {
        console.error('Error during login:', error);
        alert("There was an error during login. Please try again later.");
      }

      this.resetForm();
    },
    resetForm() {
      this.username = '';
      this.password = '';
    }
  }
};
</script>

<style scoped>
.login-form {
  width: 300px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-form h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.login-form input[type="text"],
.login-form input[type="password"] {
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.8);
  color: #333;
}

.login-form input[type="text"]:focus,
.login-form input[type="password"]:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}

.login-form button {
  width: 100%;
  padding: 15px;
  background-color: rgba(0, 115, 255, 0.82);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-form button:hover {
  background-color: #0056b3;
}
</style>