<template>
  <div id="app">
    <router-view></router-view>
  </div>
  <footer class="icp-footer">
    <p>&copy; 2024 <a href="https://github.com/EscapeTheWind?tab=repositories/" target="_blank"
                      style="color: white; text-decoration: none;" onmouseover="this.style.color='#97c4e8';"
                      onmouseout="this.style.color='white';">Mason Lynn.</a> <a href="https://beian.miit.gov.cn/"
                                                                                target="_blank"
                                                                                style="color: white; text-decoration: none;"
                                                                                onmouseover="this.style.color='#97c4e8';"
                                                                                onmouseout="this.style.color='white';">苏ICP备2024124708号</a>
    </p>
    <p></p>
  </footer>
</template>

<script>
// 避免"ResizeObserver loop completed with undelivered notifications."报错
const debounce = (fn, delay) => {
  let timer
  return (...args) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
    callback = debounce(callback, 10);
    super(callback);
  }
}
</script>

<style>
body {
  font-family: 'Arial', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-image: linear-gradient(to bottom right, #6a11cb 0%, #2570fc 100%);
}

.icp-footer {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 0;
  text-align: center;
  color: #ffffff;
}
</style>