import { createRouter, createWebHistory } from 'vue-router';
import LoginView from "../components/LoginView.vue";
import HomeView from "../components/HomeView.vue";
import FoodView from "../components/FoodView.vue";

const routes = [
    { path: '/', component: LoginView },
    { path: '/login', component: LoginView },
    { path: '/home', component: HomeView },
    { path: '/food', component: FoodView },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;